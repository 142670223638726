<template>
  <div class="">
   
      <div class="w-32 px-4 py-3">
        <div class="primaryColor fsize14 pb-1">User Type</div>

        <v-radio-group v-model="userType" row class="mt-0 pt-2">
          <v-radio
            @click="callInactiveUsers()"
            label="All"
            name="active"
            value="all"
          ></v-radio>
          <v-radio
            label="Individual"
            name="active"
            value="individual"
          ></v-radio>
        </v-radio-group>
      </div>
      <div v-if="userType == 'individual'" class="d-flex align-center gap-4">
        <v-form
      ref="userForm"
      v-model="formValid"
      lazy-validation class="d-flex align-center gap-4 px-4 py-3"
      @submit.prevent="getStatusByUser()"
    >
        <div class="w-320">
          <div>
            <div class="primaryColor fsize14 pb-1">User ID</div>
            <v-text-field
              :disabled="userType != 'individual'"
              dense
              @keypress="keyPressAlphaNumeric($event)"
              @input="userId ? (userId = userId.toUpperCase()) : null"
              v-model="userId"
              placeholder="Enter User Id"
              outlined
              :rules="userType == 'individual' ? userIdRules : null"
            >
            </v-text-field>
          </div>
        </div>
        <div>
          <v-btn
            :loading="loader"
            height="40"
            width="96"
            depressed
            type="submit"
            
            color="primary"
            class="mr-3 text-capitalize"
            >Submit</v-btn
          >
        </div>
      </v-form>
      </div>
    
    <section
      v-if="userType == 'individual' && userStatus.length > 0"
      class="d-flex gap-4 px-3"
    >
      <h5>User Status:</h5>
      <h5>{{ userStatus[0] ? getStatus(userStatus[0].status) : "" }}</h5>
    </section>

    <section
      v-if="userType == 'all'"
      class="px-3 w-full d-flex justify-space-between"
    >
      <div class="py-3 d-flex flex-wrap justify-space-between">
        <div>
          <v-slide-group v-model="subTab" mandatory>
            <v-slide-item
              v-for="n in subTabsList"
              :key="n"
              v-slot="{ active, toggle }"
              :value="n"
            >
              <span @click="getAllTask(n)">
                <v-btn
                  :title="n"
                  depressed
                  height="26"
                  class="fsize12 text-capitalize mr-2"
                  @click="toggle"
                  :color="active ? 'primary' : 'grey lighten-3'"
                  >{{ n }}</v-btn
                >
              </span>
            </v-slide-item>
          </v-slide-group>
        </div>
      </div>
      <div
        class="py-3"
        v-if="
          userType == 'all' &&
          subTab == 'Transaction Log' &&
          transactionLogs.length > 0
        "
      >
        <v-btn
          height="26"
          depressed
          min-width="fit-content"
          type="submit"
          @click="
            checkDownload('Transaction Logs', 'transaction', transactionLogs)
          "
          color="primary"
          class="mr-3 text-capitalize"
        >
          <span class="px-2">Download</span>
          <v-icon medium>mdi-cloud-download </v-icon>
        </v-btn>
      </div>
      <div
        class="py-3"
        v-if="
          userType == 'all' &&
          subTab == 'Inactive Users' &&
          userByStatus.length > 0
        "
      >
        <v-btn
          height="26"
          depressed
          min-width="fit-content"
          type="submit"
          @click="checkDownload('Inactive Users', 'inactive', userByStatus)"
          color="primary"
          class="mr-3 text-capitalize"
        >
          <span class="px-2">Download</span>
          <v-icon medium>mdi-cloud-download </v-icon>
        </v-btn>
      </div>
    </section>
    <section
      v-if="userType == 'all' && subTab == 'Transaction Log'"
      class="d-flex gap-4 px-3"
    >
      <div class="">
        <v-form
          ref="formTransaction"
          v-model="valid"
          @submit.prevent="callTransactionLogs()"
          lazy-validation
        >
          <div class="d-flex align-center">
            <!-- <div class="w-212">
                <div>
                  <div class="primaryColor fsize14 pb-1">User ID</div>
                  <v-text-field
                    dense
                    @keypress="keyPressAlphaNumeric($event)"
                    @input="userId ? (userId = userId.toUpperCase()) : null"
                    v-model="userId"
                    placeholder="Enter User Id"
                    outlined
                    :rules="userIdRules"
                  >
                  </v-text-field>
                </div>
              </div> -->
            <div class="pa-4">
              <div class="pb-1">*From Date</div>
              <v-menu
                v-model="dateMenu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click:append="dateMenu1 = true"
                    class="apiKeyInput"
                    v-model="computedDateFormatted1"
                    outlined
                    persistent-hint
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="fromDateRules"
                    placeholder="Select the From Date"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  :show-current="true"
                  no-title
                  :max="new Date().toISOString().substr(0, 10)"
                  @input="dateMenu1 = false"
                >
                </v-date-picker>
              </v-menu>
            </div>

            <div class="pa-4">
              <div class="pb-1">*To Date</div>
              <v-menu
                v-model="dateMenu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    @click:append="dateMenu2 = true"
                    class="apiKeyInput"
                    v-model="computedDateFormatted2"
                    outlined
                    persistent-hint
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="toDateRules"
                    placeholder="Select the To Date"
                    append-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  :show-current="true"
                  no-title
                  :max="new Date().toISOString().substr(0, 10)"
                  :min="this.fromDate"
                  @input="dateMenu2 = false"
                >
                </v-date-picker>
              </v-menu>
            </div>
            <v-btn
              type="submit"
              height="40"
              depressed
              color="primary"
              class="text-capitalize fsize14"
              >Submit</v-btn
            >
          </div>
        </v-form>
      </div>
    </section>

    <v-data-table
      v-if="userReason.length > 0 && userType == 'individual'"
      :headers="individualTitle"
      :items="userReason"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center">
            <span class="pr-4">{{ props.item.createdOn }}</span>
          </td>
          <td class="text-center">
            <span class="pr-4">
              {{ props.item.status ? getStatus(props.item.status) : "" }}</span
            >
          </td>
          <td class="text-center">
            <span class="pr-4">{{ props.item.reason }} </span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      v-if="
        userByStatus.length > 0 &&
        userType == 'all' &&
        subTab == 'Inactive Users'
      "
      :headers="inactiveUsersTitle"
      :items="userByStatus"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center">
            <span class="pr-4">{{ props.index + 1 }}</span>
          </td>
          <td class="text-center">
            <span class="pr-4"> {{ props.item.userId }}</span>
          </td>
          <td class="text-center">
            <span class="pr-4"> {{ props.item.updatedOn ? formattingDate(props.item.updatedOn, 'D&T') : 'NA' }}</span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-data-table
      v-if="
        transactionLogs.length > 0 &&
        userType == 'all' &&
        subTab == 'Transaction Log'
      "
      :headers="titlename"
      :items="transactionLogs"
      :search="search"
      :items-per-page="10"
      class="elevation-1"
    >
      <template v-slot:item="props">
        <tr>
          <td class="text-center">
            <span class="pr-4">{{ props.item.createdOn }}</span>
          </td>
          <td class="text-center">
            <span class="pr-4"> {{ props.item.userId }}</span>
          </td>
          <td class="text-center">
            <span class="pr-4">
              {{ props.item.status ? getStatus(props.item.status) : "" }}</span
            >
          </td>
          <td class="text-center">
            <span class="pr-4"> {{ props.item.reason }}</span>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div
      v-else-if="
        transactionLogs.length <= 0 &&
        userType == 'all' &&
        subTab == 'Transaction Log'
      "
      class="d-flex align-center justify-center min-height-100"
    >
      No Records Found
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex/dist/vuex.common.js";
import commonFunctions from "../../mixins/commonFunctions";
export default {
  data() {
    return {
      search: "",
      formValid: true,
      userType: "individual",
      subTab: "Inactive Users",
      userId: "",
      userIdRules: [(v) => !!v || "User Id is required"],
      subTabsList: ["Inactive Users", "Transaction Log"],
      valid: true,
      dateMenu1: false,
      dateMenu2: false,
      fromDateRules: [(v) => !!v || "From Date is required"],
      toDateRules: [(v) => !!v || "To Date is required"],
      fromDate: null,
      toDate: null,
      inactiveUsersTitle: [
        {
          text: "S.No",
          align: "center",
          sortable: false,
          value: "sno",
        },
        {
          text: "User Id",
          align: "center",
          sortable: true,
          value: "userId",
        },
        {
          text: "Created On",
          align: "center",
          sortable: true,
          value: "updatedOn",
        },
      ],
      individualTitle: [
        {
          text: "Updated On",
          align: "center",
          sortable: true,
          value: "createdOn",
        },

        {
          text: "Status",
          align: "center",
          sortable: true,
          value: "status",
        },
        {
          text: "Reason",
          align: "center",
          sortable: true,
          value: "reason",
        },
      ],
      titlename: [
        {
          text: "Updated On",
          align: "center",
          sortable: true,
          value: "createdOn",
        },
        {
          text: "User ID",
          align: "center",
          sortable: true,
          value: "userId",
        },
        {
          text: "Status",
          align: "center",
          sortable: true,
          value: "status",
        },
        {
          text: "Reason",
          align: "center",
          sortable: true,
          value: "reason",
        },
      ],
    };
  },
  mixins: [commonFunctions],
  computed: {
    ...mapState("reports", [
      "userStatus",
      "userReason",
      "userByStatus",
      "transactionLogs",
    ]),
    ...mapState(["loader"]),
    computedDateFormatted1: {
      get() {
        return this.VutifyFormatDate(this.fromDate,'/');
      },
      set() {},
    },
    computedDateFormatted2: {
      get() {
        return this.VutifyFormatDate(this.toDate,'/');
      },
      set() {},
    },
  },
  methods: {
    getStatusByUser() {
      if (this.$refs.userForm.validate()) {
        let json = {
          userId: this.userId,
        };
        this.$store.dispatch("reports/getStatusByUser", json);
        this.$store.dispatch("reports/getReasonByUser", json);
      }
    },
    getMaxDate() {
      let date = new Date();
      let newMonth = date.getMonth() + 1;
      date.setMonth(newMonth);
      return date.toISOString().slice(0, 10);
    },
    getMinDate() {
      return new Date().toISOString().slice(0, 10);
    },
    callInactiveUsers() {
      this.userId = "";
      this.$store.commit("reports/setUserReason", []);
      this.$store.commit("reports/setUserStatus", []);
      let json = {
        status: "D",
      };
      this.$store.dispatch("reports/getUserByStatusInactive", json);
    },
    getStatus(value) {
      if (value == "D") {
        return "Deactive";
      } else if (value == "A") {
        return "Active";
      } else {
        return "";
      }
    },

    keyPressAlphaNumeric(event) {
      var inp = String.fromCharCode(event.keyCode);

      if (event.keyCode != 13) {
        if (/[a-zA-Z0-9,]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },
    callTransactionLogs() {
      if (this.$refs.formTransaction.validate()) {
        let json = {
          fromDate: this.fromDate,
          toDate: this.toDate,
          pageNumber: "",
          pageSize: "127",
          userId: this.userId,
        };

        this.$store.dispatch("reports/getUserReasonsWithPagination", json);
      }
    },
    getAllTask(type) {
      this.$store.commit("reports/setUserByStatus", []);
      this.$store.commit("reports/setTransactionLogs", []);
      this.fromDate = null;
      this.toDate = null;
      this.userId = "";

      type == "Inactive Users" ? this.callInactiveUsers() : "";
    },
  },
};
</script>
<style>
.w-212 {
  width: 212px !important;
}
</style>
